import { $EventBus } from '@/main';
import { mapActions, mapState } from 'vuex';
import { registerStatus, isDraft, getStatus } from '@/store/services/register-status.js';
import textTransform from '@/helpers/textTransform.js';
import moment from 'moment';

export default {
	name: 'RegistersList',
	data: () => ({
		title: '',
		search: '',
		headers: [],
		dateRange: {},
		loading: false,
		registerStatus: [
			...[{ label: 'Todos los estados', value: '' }],
			...registerStatus,
		],
		filters: {
			status: '',
			q: '',
			start_date: null,
			end_date: null,
			pagination: 1,
			per_page: process.env.VUE_APP_PAGINATION_PER_PAGE || 10,
			page: 1,
		},
	}),
	computed: {
		...mapState('register', ['registers', 'registers_pagination']),

		inspection() {
			return this.$store.state.register.registers_inspection || null;
		},
		inspection_code() {
			return this.$route?.params?.id || null;
		},
		breadcrumbs() {
			return [
				{
					text: 'Inspecciones',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'InspectionsList',
					},
				},
				{
					text: this.inspection ? textTransform(this.inspection.name) : '',
					disabled: true,
					href: '/',
				},
			];
		},
	},
	created() {
		this.getInspection(this.inspection_code);
		$EventBus.$emit('showSubNav', false);
	},
	methods: {
		...mapActions('register', ['list', 'cleanRegisters']),

		textTransform,

		getData() {
			this.loading = true;
			let filters = { ...this.filters };
			if ((filters?.q || '').length < 2) {
				delete filters.q;
			}
			this.list({
				success: () => {
					this.loading = false;
				},
				fail: () => {
					this.loading = false;
				},
				id: this.inspection?.format,
				filters: filters,
			});
		},
		getInspection(id) {
			this.loading = true;
			this.list({
				success: () => {
					this.setHeaders();
					this.loading = false;
				},
				fail: () => {
					this.loading = false;
				},
				id,
				filters: this.filters,
			});
		},
		listenDatePicker(val) {
			this.filters.start_date = val[0] ? moment(val[0]).format('YYYY-MM-DD') : '';
			this.filters.end_date = val[1] ? moment(val[1]).format('YYYY-MM-DD') : '';
			this.filters.page = 1;
			this.getData();
		},
		formatDateString(date) {
			let dateString = '';
			if (date) {
				let d = date.toString().substring(0, 10).split('-');
				dateString = `${d[2]}-${d[1]}-${d[0]}`;
			}
			return dateString;
		},
		changeFilter() {
			this.filters.page = 1;
			this.getData();
		},
		setHeaders() {
			if (this.inspection) {
				this.headers = [
					{
						text: 'Fecha',
						value: 'date',
						sortable: false,
						class: 'primary--text',
					},
					{
						text: 'Área/Taller/Estación',
						value: 'area_workshop_station',
						align: 'null',
						sortable: false,
						class: 'primary--text text-center',
					},
					{
						text: 'Criterios aplicados',
						value: 'itemsTotal',
						align: 'center',
						sortable: false,
						class: 'primary--text',
					},
					{
						text: 'Cumplimiento',
						value: 'itemsSi',
						align: 'center',
						sortable: false,
						class: 'primary--text',
					},
					{
						text: 'Incumplimiento',
						value: 'itemsNo',
						align: 'center',
						sortable: false,
						class: 'primary--text',
					},
					{
						text: 'N/A',
						value: 'itemsNa',
						align: 'center',
						sortable: false,
						class: 'primary--text',
					},
					{
						text: 'Estado',
						value: 'status',
						align: 'center',
						sortable: false,
						class: 'primary--text',
					},
					{
						text: 'Acciones',
						value: 'show',
						align: 'center',
						sortable: false,
						class: 'primary--text',
					},
				];
			}
		},
		valIsDraft(value) {
			return isDraft(value);
		},
		getStatusRegister(value) {
			return getStatus(value);
		},
		listenTextSearch() {
			if ((this.filters.q || '').length > 1 || this.filters.q == '') {
				this.filters.page = 1;
				this.getData();
			}
		},
	},
	destroyed() {
		this.cleanRegisters();
	},
};
