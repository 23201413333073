var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"grey lighten-2 d-flex justify-center"},[_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumbs},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("mdi-chevron-right")])]},proxy:true}])})],1),_c('v-container',[_c('v-row',{attrs:{"align":"center"}},[(_vm.inspection)?_c('v-col',{attrs:{"cols":"12","md":"auto"}},[_c('h1',{staticClass:"title font-weight-medium primary--text"},[_vm._v(" "+_vm._s(_vm.textTransform(_vm.inspection.name) + ' - ' + _vm.inspection.code)+" ")])]):_vm._e(),_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[_c('validate-activity',{attrs:{"module":"inspeccion","page":"mostrarReporte","activity":"mostrarReporte"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isDisabled = ref.isDisabled;
return [(_vm.inspection_code)?_c('v-btn',{staticClass:"my-1 text-none",attrs:{"disabled":isDisabled,"to":{
							name: 'InspectionsRegisterCreate',
							params: { inspectionId: _vm.inspection_code },
						},"color":"primary","rounded":"","link":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Nuevo registro ")],1):_vm._e()]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('date-picker',{attrs:{"range":"","format":"DD-MM-YYYY","placeholder":"Seleccionar rango de fechas"},on:{"input":function (val) {
							_vm.listenDatePicker(val);
						}},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-select',{attrs:{"items":_vm.registerStatus,"label":"Todos los estados","item-text":"label","item-value":"value","single-line":"","dense":"","hide-details":"","solo":""},on:{"input":_vm.changeFilter},model:{value:(_vm.filters.status),callback:function ($$v) {_vm.$set(_vm.filters, "status", $$v)},expression:"filters.status"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar por Área/Taller/Estación","single-line":"","dense":"","hide-details":"","solo":""},on:{"input":function($event){return _vm.listenTextSearch()}},model:{value:(_vm.filters.q),callback:function ($$v) {_vm.$set(_vm.filters, "q", $$v)},expression:"filters.q"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.registers,"loading":_vm.loading,"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
						var item = ref.item;
return [_c('span',{staticClass:"grey--text",domProps:{"textContent":_vm._s(_vm.formatDateString(item.date))}})]}},{key:"item.manager",fn:function(ref){
						var item = ref.item;
return [_c('span',{staticClass:"grey--text",domProps:{"textContent":_vm._s(item.manager_names)}})]}},{key:"item.subprocess",fn:function(ref){return [_c('span',{staticClass:"grey--text",domProps:{"textContent":_vm._s(_vm.inspection.subprocess.name)}})]}},{key:"item.itemsTotal",fn:function(ref){
						var item = ref.item;
return [_c('div',{staticClass:"font-weight-medium"},[_c('span',{staticClass:"grey--text mr-2"},[_vm._v(" "+_vm._s(item.items_yes + item.items_no)+" ")])])]}},{key:"item.itemsSi",fn:function(ref){
						var item = ref.item;
return [_c('div',{staticClass:"font-weight-medium"},[_c('span',{staticClass:"mr-2",staticStyle:{"color":"#65c118"}},[_vm._v(" "+_vm._s(item.items_yes)+" ")])])]}},{key:"item.itemsNo",fn:function(ref){
						var item = ref.item;
return [_c('div',{staticClass:"font-weight-medium"},[_c('span',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(item.items_no)+" ")])])]}},{key:"item.itemsNa",fn:function(ref){
						var item = ref.item;
return [_c('div',{staticClass:"font-weight-medium"},[_c('span',{staticClass:"error--text"},[_vm._v(" "+_vm._s(item.items_na)+" ")])])]}},{key:"item.status",fn:function(ref){
						var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2 white--text",attrs:{"small":"","color":_vm.getStatusRegister(item.status).color}},[_vm._v(" "+_vm._s(_vm.getStatusRegister(item.status).label)+" ")])]}},{key:"item.show",fn:function(ref){
						var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('validate-activity',{attrs:{"module":"inspeccion","page":"crearRegistro","activity":"crearRegistro"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
						var isDisabled = ref.isDisabled;
return [(item.status == 'DRAFT')?_c('v-btn',{staticClass:"text-none",attrs:{"disabled":isDisabled,"to":{
											name: 'InspectionsRegisterDraft',
											params: {
												inspectionId: _vm.inspection.format,
												id: item.id,
											},
										},"color":"primary","text":"","link":""}},[_vm._v(" Editar ")]):_vm._e()]}}],null,true)}),_c('validate-activity',{attrs:{"module":"inspeccion","page":"mostrarRegistro","activity":"mostrarRegistro"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
										var isDisabled = ref.isDisabled;
return [_c('v-btn',{staticClass:"text-none",attrs:{"disabled":isDisabled,"to":{
											name: 'InspectionsRegisterShow',
											params: {
												inspectionId: _vm.inspection.format,
												id: item.id,
											},
										},"color":"primary","text":"","link":""}},[_vm._v(" Ver "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-chevron-right ")])],1)]}}],null,true)})],1)]}}],null,true)})],1),_c('div',{staticClass:"text-center py-2"},[(_vm.registers_pagination)?_c('v-pagination',{attrs:{"length":_vm.registers_pagination.pages || 0},on:{"input":function($event){return _vm.getData()}},model:{value:(_vm.filters.page),callback:function ($$v) {_vm.$set(_vm.filters, "page", $$v)},expression:"filters.page"}}):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }